@charset "UTF-8";

/* global settings */
@import 'settings/settings';

/* functions */
@import 'utilities/functions';

#main {
  overflow-x: hidden;

  > * {
    display: block;
    margin-left: 5%;
    margin-right: 5%;

    @include breakpoint($small) {
      margin-left: 2.5%;
      margin-right: 2.5%;
    }

    @include breakpoint(1400px) {
      max-width: $content-max-width;
      margin-left: auto;
      margin-right: auto;
    }
  }

  > .component-full-width {
    max-width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.component-spacing-top {
  overflow: visible;
  margin-top: $spacing_content;

  @include breakpoint($large) {
    margin-top: $spacing_content_large;
  }
}

.component-spacing-bottom {
  overflow: visible;
  margin-bottom: $spacing_content;

  @include breakpoint($large) {
    margin-bottom: $spacing_content_large;
  }
}

.component-spacing-bottom + .component-spacing-top {
  &::before {
    content: '';
    width: 100vw;
    height: 1px;
    background: $black-10;
    position: absolute;
    left: 0;
    margin-top: -$spacing_content;
  }

  @include breakpoint($large) {
    &::before {
      margin-top: -$spacing_content_large;
    }
  }

  margin-top: $spacing_content * 2;

  @include breakpoint($large) {
    margin-top: $spacing_content_large * 2;
  }
}

.component-successive-spacing-top {
  margin-top: $spacing_successive_content;
  position: relative;

  @include breakpoint($large) {
    margin-top: $spacing_successive_content_large;
  }
}

.component-successive-spacing-bottom {
  margin-bottom: $spacing_successive_content;

  @include breakpoint($large) {
    margin-bottom: $spacing_successive_content_large;
  }
}

.breadcrumb-wrapper {
  padding: 20px 0;
}

.back-to-page-wrapper {
  padding: 20px 0 0;
}

// fix for IE11
_:-ms-fullscreen,
:root .component-spacing-bottom + .component-spacing-top::before {
  width: auto;
  left: 0;
}

/* On products category we do not show back link when breadcrumbs is visible */
@include breakpoint($medium) {
  .has-xproductcategoryoverview .back-to-page-wrapper {
    display: none;
  }
}
